<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="400px"
        :visible.sync="showDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >
        <div slot="title" class="title">{{ title }}</div>
        <div class="content" v-loading="loading">
            <el-tree
                v-if="!loading"
                ref="tree"
                :props="{label: 'name',children: 'zones',isLeaf: 'leaf'}"
                :load="loadNode"
                :check-strictly="true"
                lazy
                node-key="id"
                @check="treeCheck"
                show-checkbox>
            </el-tree>
        </div>
        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="save">确定</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>

import {initTreeData} from "@/util/common";
import {findByDept} from "@/api/controller/admin/user";

export default {
    components: {},
    props: {
        title: {
            type: String,
            default: '请选择成员'
        },
        singleChoice: {
            type: Boolean,
            default: true
        },
        isShow: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Function,
            default: () => {}
        },
        submit:{
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            showDialog: false,
            submitLoading: false,
            loading: true,
            deptData:[],
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showDialog = newVal;
            }
        },
    },
    created(){
        this.$api['admin/dept'].findByList().then(res => {
            this.deptData  = res.data;
            this.loading = false;
        }).catch(err => {
            console.log(err);
            this.loading = false;
        });
    },
    mounted() {

    },
    methods: {
        treeCheck:function(node,list){
            if (this.singleChoice&&list.checkedKeys.length == 2) {
                 this.$refs.tree.setCheckedKeys([node.id]);
            }
        },
        save(){
            const arr = this.$refs.tree.getCheckedNodes(true);
            if(arr.length>0){
                this.submitLoading = true;
                this.submit(arr).then( res => {
                    this.submitLoading = false;
                    this.refresh();
                    this.$message.success("操作成功！");

                }).catch(err=>{
                    this.submitLoading = false;
                    console.log(err);
                })
            }else {
                this.$message.error(this.title);
            }
        },
        loadNode(node,reolve){
            if (node.level === 0) {
                var arr = [];
                this.deptData.some(r=>{
                    if(r.parentId==0){
                        arr.push({
                            type:'dept',
                            name:r.deptName,
                            id:r.id,
                            disabled:this.singleChoice,
                        });
                    };
                })
                reolve(arr);
            }else {

                if(node.data.type=='dept'){

                    this.$api['admin/user'].findByDept(node.data.id).then(res => {
                        var arr = []
                        res.data.some(r=>{
                            arr.push(r.combination({
                                leaf:true,
                            }));
                        });
                        this.deptData.some(r=>{
                            if(r.parentId==node.data.id){
                                arr.push({
                                    type:'dept',
                                    name:r.deptName,
                                    id:r.id,
                                    disabled:this.singleChoice,
                                });
                            };
                        })
                        reolve(arr);
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }
        }
    }
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.content {
    height:50vh;
}
</style>
